import React from "react";
import { StyledAnchor } from "../HireTalent/HireTalent.styles";
import {
  HireDevelopersContainer,
  StyledButton,
  StyledText,
  ButtonContainer,
} from "./HireDevelopers.styles";

const HireDevelopers = () => (
  <HireDevelopersContainer>
    <StyledText weight="semiBold">
      Let us help you catapult your company’s growth!
    </StyledText>
    <ButtonContainer>
      <StyledAnchor
        target="_blank"
        href="https://airtable.com/shrurCjjt7n9KyAWn"
      >
        <StyledButton label="Apply for a Role" weight="semiBold" />
      </StyledAnchor>
      <StyledAnchor
        target="_blank"
        href="https://airtable.com/shrtXYrqddfQr5nbN"
      >
        <StyledButton label="Hire Tech Talent" weight="semiBold" />
      </StyledAnchor>
    </ButtonContainer>
  </HireDevelopersContainer>
);

export default HireDevelopers;
