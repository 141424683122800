import * as React from "react";
import Slider from "react-slick";
import Modal from "react-modal";

import {
  Opacity,
  OuterContainer,
  StyledContainer,
  StyledHeading,
  TextContainer,
  StyledText,
  TrustedByOurClientsContainer,
  TestimonailAuthor,
  TestimonialContainer,
  TestimontialText,
  TestimontialImage,
  StyledButton,
  ShowMoreLessButton,
  StyledSubTitle,
  ModalTitle,
  ModalCloseButton,
  ModalCompany,
  ModalContent,
  ModalFounderImage,
  ModalQuote,
} from "./TrustedByOurClients.styles";
import { Paragraph } from "../Typography/Typography";
import { useMediaQuery, useTestimonialQuery } from "../../hooks";
import { size } from "../../theme/sizes";
import { sliderCommonSettings } from "../../utils";

const TrustedByOurClients = () => {
  const isMobile = useMediaQuery(size.tabletL);
  const [openModalId, setOpenModalId] = React.useState<string | null>(null);
  const testimonials = useTestimonialQuery();

  const openModal = (id: string) => {
    setOpenModalId(id);
  };

  const closeModal = () => {
    setOpenModalId(null);
  };

  const sliderConfig = {
    ...sliderCommonSettings,
    slidesToShow: isMobile ? 1 : 3,
  };

  return (
    <TrustedByOurClientsContainer>
      <Opacity />
      <OuterContainer>
        <StyledHeading weight="semiBold">What Our Clients Say</StyledHeading>

        <StyledSubTitle>
          We have a track record of delivering top notch results. Don’t just
          hear it from us! Here are what some of our star clients say about us.
        </StyledSubTitle>
        <StyledContainer>
          <Slider {...sliderConfig} style={{ width: "100%", margin: "0" }}>
            {testimonials.map((node, index: number) => (
              <TextContainer key={index}>
                <TestimontialText>
                  {node.testimonial.testimonial.substring(0, 160)}...
                  <ShowMoreLessButton
                    label="Read More"
                    variant="outline"
                    onClick={() => openModal(node.id)}
                  />
                </TestimontialText>
                <TestimonialContainer>
                  {!isMobile && (
                    <TestimontialImage
                      src={node.linkedInProfileImageLink}
                      alt={node.fullName}
                      width="143px"
                    />
                  )}
                  {isMobile && (
                    <TestimontialImage
                      src={node.linkedInProfileImageLink}
                      alt={node.fullName}
                      width="143px"
                    />
                  )}
                  <TestimonailAuthor>
                    <Paragraph>{node.fullName}</Paragraph>
                    <StyledText>
                      {node.title}, {node.companyName}
                    </StyledText>
                  </TestimonailAuthor>
                </TestimonialContainer>

                <Modal
                  isOpen={openModalId === node.id}
                  onRequestClose={closeModal}
                  style={{
                    overlay: {
                      backgroundColor: "rgba(0, 0, 0, 0.1)", // Translucent overlay
                    },
                    content: {
                      display: "flex",
                      alignItems: "center",
                      top: "50%",
                      left: "50%",
                      marginRight: "-50%",
                      right: "auto",
                      bottom: "auto",
                      transform: "translate(-50%, -50%)",
                      border: "none",
                      borderRadius: isMobile ? 0 : 8,
                      backgroundColor: "white",
                    },
                  }}
                >
                  <ModalCloseButton onClick={closeModal}>
                    &times;
                  </ModalCloseButton>
                  <ModalContent>
                    <ModalFounderImage
                      src={node.linkedInProfileImageLink}
                      alt={node.fullName}
                    />
                    <ModalTitle>{node.fullName}</ModalTitle>
                    <ModalCompany>
                      {node.title}, {node.companyName}
                    </ModalCompany>
                    <ModalQuote>"{node.testimonial.testimonial}"</ModalQuote>
                  </ModalContent>
                </Modal>
              </TextContainer>
            ))}
          </Slider>
        </StyledContainer>
      </OuterContainer>
    </TrustedByOurClientsContainer>
  );
};

export default TrustedByOurClients;
