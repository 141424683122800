import styled from "styled-components";
import { device } from "../../theme/sizes";
import Button from "../Button/Button";
import { StyledParagraph } from "../Button/Button.styles";
import { Heading, Paragraph } from "../Typography/Typography";

export const HeroTextContainer = styled.div`
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;
  margin-top: 65px;

  @media ${device.tabletL} {
    flex-direction: column;
    padding: 60px 0;
  }
`;

export const HeroTitle = styled(Heading)`
  font-size: 44px;
  line-height: 56px;
  margin-bottom: 12px;

  @media ${device.tabletL} {
    font-size: 32px;
    line-height: 44px;
  }
`;

export const HeroSubtitle = styled(Paragraph)`
  font-size: 18px;
  line-height: 30px;
`;

export const HeroButtonContainer = styled.div`
  display: flex;

  @media ${device.tabletL} {
    flex-direction: column;
  }
`;

export const HeroButton = styled(Button)`
  margin: 1.25em 1em 0 0;

  ${StyledParagraph} {
    font-size: 20px;
    line-height: 28px;
  }

  @media ${device.tabletL} {
    ${StyledParagraph} {
      font-size: 16px;
      line-height: 20px;
    }
  }
`;

export const HeroText = styled.div`
  width: 604px;

  @media ${device.tabletL} {
    width: auto;
  }
`;

export const HeroImageContainer = styled.div`
  @media ${device.tabletL} {
    margin-top: 21px;
  }
`;

export const HeroImage = styled.img`
  width: 100%;
`;

export const TalentGallery = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-top: 118px;
  margin-bottom: 92px;
  @media ${device.tabletL} {
    text-align: center;
    margin-top: 25px;
    margin-bottom: 60px;
  }
`;

export const Title = styled(Paragraph)`
  font-size: 24px;
  line-height: 28px;
  color: ${({ theme }) => theme.colors.gray};
  margin-bottom: 24px;

  @media ${device.tabletL} {
    margin-top: 21px;
  }
`;

export const TalentsList = styled.div`
  gap: 28px;
  margin: auto;
`;

export const ClientImageContainer = styled.div`
  width: 100px;
  margin: 2em 0;

  @media ${device.tabletL} {
    margin: 1em 0;
  }
`;

export const ClientLogoImage = styled.img`
  width: 30%;
  margin: auto;

  @media ${device.tabletL} {
    width: 40%;
  }
`;
