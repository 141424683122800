import styled from "styled-components";
import { device } from "../../theme/sizes";
import { Heading, Paragraph } from "../Typography/Typography";

export const GrowingTogetherContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  padding-bottom: 90px;

  @media ${device.tabletL} {
    flex-direction: column;
    padding: 60px 0;
    text-align: left;
  }
`;

export const GrowingTogetherTitle = styled(Heading)`
  margin-bottom: 24px;
  max-width: 1016px;
  @media ${device.tabletL} {
    font-size: 32px;
    line-height: 40px;
  }
`;

export const StyledParagraph = styled(Paragraph)`
  max-width: 1016px;
  margin-bottom: 48px;
  @media ${device.tabletL} {
    font-size: 16px;
    line-height: 26px;
  }
`;

export const GrowingTogetherImage = styled.img`
  width: 100%;
  max-width: 810px;
`;
