import * as React from "react";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Layout from "../components/Layout/Layout";
import Hero from "../components/Hero/Hero";
import HireTalent from "../components/HireTalent/HireTalent";
import Footer from "../components/Footer";
import GrowingTogether from "../components/GrowingTogether/GrowingTogether";
import MeetOurFounder from "../components/MeetOurFounder/MeetOurFounder";
import HireDevelopers from "../components/HireDevelopers/HireDevelopers";
import TrustedByOurClients from "../components/TrustedByOurClients/TrustedByOurClients";
import ScrollHandler from "../components/ScrollHander";

const IndexPage = () => (
  <>
    <ScrollHandler />
    <Layout>
      <Hero />
      <HireTalent />
      <TrustedByOurClients />
      <MeetOurFounder />
      <GrowingTogether />
      <HireDevelopers />
      <Footer />
    </Layout>
  </>
);

export default IndexPage;
