import * as React from "react";
import Slider from "react-slick";

import {
  HeroButton,
  HeroText,
  HeroSubtitle,
  TalentGallery,
  Title,
  ClientImageContainer,
  ClientLogoImage,
  HeroImageContainer,
  HeroImage,
  HeroTextContainer,
  HeroTitle,
  HeroButtonContainer,
} from "./Hero.styles";

import hero from "../../images/hero.svg";
import { useMediaQuery, useClientLogoQuery } from "../../hooks";
import { size } from "../../theme/sizes";
import { sliderCommonSettings } from "../../utils";
import {StyledButton, StyledLink} from "../HireTalent/HireTalent.styles";

const Hero = () => {
  const isMobile = useMediaQuery(size.tabletL);
  const clientLogos = useClientLogoQuery();

  const sliderConfig = {
    ...sliderCommonSettings,
    slidesToShow: isMobile ? 3 : 5,
  };

  return (
    <>
      <HeroTextContainer>
        <HeroText>
          <HeroTitle weight="semiBold">
            Your Key Partner in Hiring Tech Talent.
          </HeroTitle>
          <HeroSubtitle>
            Hire Top Remote Engineers, Chief Technology Officers and Product
            Managers
          </HeroSubtitle>
          {!isMobile && (
            <HeroButtonContainer>
              <a target="_blank" href="https://airtable.com/shrurCjjt7n9KyAWn">
                <HeroButton label="Apply for a Role" weight="semiBold" />
              </a>
              <StyledLink
                  to="/what-we-offer/hire-tech-talent"
              >
                <HeroButton label="Hire Tech Talent" weight="semiBold" />
              </StyledLink>
            </HeroButtonContainer>
          )}
        </HeroText>
        <HeroImageContainer>
          <HeroImage src={hero} alt="Hero Image" />
        </HeroImageContainer>
        {isMobile && (
          <HeroButtonContainer>
            <a target="_blank" href="https://airtable.com/shrurCjjt7n9KyAWn">
              <HeroButton label="Apply for a Role" weight="semiBold" />
            </a>

            <StyledLink
                to="/what-we-offer/hire-tech-talent"
            >
              <HeroButton label="Hire Tech Talent" weight="semiBold" />
            </StyledLink>
          </HeroButtonContainer>
        )}
      </HeroTextContainer>
      <TalentGallery>
        <Title weight="medium">Our Client Portfolio</Title>
        <Slider {...sliderConfig} style={{ width: "100%", margin: "0" }}>
          {clientLogos.map((node, index: number) => (
            <ClientImageContainer key={index}>
              <ClientLogoImage
                src={node.linkedInCompanyLogoLink}
                alt={node.id}
              />
            </ClientImageContainer>
          ))}
        </Slider>
      </TalentGallery>
    </>
  );
};

export default Hero;
