import * as React from "react";
import useMediaQuery from "../../hooks/useMediaQuery";
import GrowingTogetherImg from "../../images/growing-together.png";
import { size } from "../../theme/sizes";
import {
  GrowingTogetherContainer,
  GrowingTogetherImage,
  GrowingTogetherTitle,
  StyledParagraph,
} from "./GrowingTogether.styles";

const GrowingTogether = () => {
  const isMobile = useMediaQuery(size.tabletL);

  return (
    <GrowingTogetherContainer id="#social-impact">
      <GrowingTogetherTitle weight="semiBold">
        Working as alphathesis Talent
      </GrowingTogetherTitle>
      <StyledParagraph>
        Our talents’ professional development is our top priority. Every
        quarter, we organize social meetups in Nairobi, Kenya for our tech
        talents to build professional relationships and network with seasoned
        tech executives from the region’s tech startup community. Hear what our
        talents have to say about their time with alphathesis!
        {isMobile && (
          <>
            <br />
            <br />
          </>
        )}
      </StyledParagraph>
      <GrowingTogetherImage
        src={GrowingTogetherImg}
        alt="Growing Together Img"
      />
    </GrowingTogetherContainer>
  );
};

export default GrowingTogether;
