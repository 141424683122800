import styled from "styled-components";
import { Heading, Paragraph } from "../Typography/Typography";
import Button from "../Button/Button";
import { StyledParagraph } from "../Button/Button.styles";
import TrustedBkg from "../../images/trusted-by-our-clients-bkg.png";
import { device } from "../../theme/sizes";

export const TrustedByOurClientsContainer = styled.div`
  position: relative;
  text-align: center;
  padding-top: 80px;

  background: url(${TrustedBkg});
  background-repeat: no-repeat;
  background-size: cover;

  @media ${device.tabletL} {
    flex-direction: column;
    padding: 32px;
    text-align: left;
    margin: 0 -32px;
  }
`;

export const Opacity = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: white;
  opacity: 0.7;
`;

export const OuterContainer = styled.div`
  position: relative;
  z-index: 0;
`;

export const StyledHeading = styled(Heading)`
  font-size: 52px;
  line-height: 64px;

  @media ${device.tabletL} {
    font-size: 32px;
    line-height: 40px;
    margin-bottom: 24px;
  }
`;

export const StyledContainer = styled.div`
  display: flex;
  gap: 40px;
  padding: 24px 96px 80px;
  text-align: center;
  justify-content: space-between;
  margin: auto;
  @media ${device.tabletL} {
    flex-direction: column;
    padding: 0;
  }
`;

export const TextContainer = styled.div`
  flex-direction: column;
  text-align: left;
  width: 100%;
  @media ${device.tabletL} {
    width: 100%;
    padding: 0;

    ${Paragraph} {
      font-size: 16px;
      line-height: 32px;
    }
  }
`;

export const StyledText = styled(Paragraph)`
  color: ${({ theme }) => theme.colors.gray};
`;

export const TestimonialContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0 auto;
`;

export const StyledSubTitle = styled(Paragraph)`
  max-width: 1016px;
  margin: 2em auto;
  @media ${device.tabletL} {
    font-size: 16px;
    margin: 1em auto;
    line-height: 26px;
  }
`;

export const TestimonailAuthor = styled.div`
  margin-left: 1em;
`;

export const TestimontialText = styled(Paragraph)`
  padding: 0.5em;
  height: 180px;

  @media ${device.tabletL} {
    height: 140px;
    padding: 2em 0;

    ${StyledParagraph} {
      font-size: 16px;
      line-height: 20px;
    }
  }
  color: ${({ theme }) => theme.colors.primaryBlack};
`;

export const TestimontialImage = styled.img`
  width: "143px";
  border-radius: 50%;
`;

export const StyledButton = styled(Button)`
  ${StyledParagraph} {
    font-size: 20px;
    line-height: 28px;
  }

  @media ${device.tabletL} {
    width: 160px;
    padding: 10px 0;

    ${StyledParagraph} {
      font-size: 16px;
      line-height: 20px;
    }
  }
`;

export const ShowMoreLessButton = styled(Button)`
  padding: 0;
  border: none;

  ${StyledParagraph} {
    font-size: 16px;
    line-height: 20px;
  }
`;

export const ModalContent = styled.div`
  background: white;
  border-radius: 8px;
  max-width: 600px;
  max-height: 600px;
  margin: 0 auto;
`;

export const ModalCloseButton = styled.button`
  position: absolute;
  top: 12px;
  right: 12px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
`;

export const ModalFounderImage = styled.img`
  width: 200px; // Adjust the size as needed
  height: 200px;
  border-radius: 50%;
  margin-bottom: 10px;
`;

export const ModalTitle = styled(Paragraph)`
  font-size: 18px;
  margin: 0;
  font-weight: bold;
`;

export const ModalCompany = styled(Paragraph)`
  margin: 5px 0;
  color: gray;
`;

export const ModalQuote = styled(Paragraph)`
  font-style: italic;
  margin: 10px 0;
  padding: 10px;
  border-left: 3px solid #3252a0; // Quote style (like a book quote)
`;
