import styled from "styled-components";
import { device } from "../../theme/sizes";
import Button from "../Button/Button";
import { StyledParagraph } from "../Button/Button.styles";
import { Paragraph, Heading } from "../Typography/Typography";
import { Link } from "gatsby";

export const MeetOurFounderContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 90px 0;

  @media ${device.tabletL} {
    flex-direction: column;
    padding: 60px 0;
    text-align: left;
  }
`;

export const FounderImage = styled.img``;

export const MeetOurFounderContentContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  gap: 32px;

  @media ${device.tabletL} {
    flex-direction: column;
    text-align: left;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  max-width: 598px;
  width: 100%;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;
  margin: 1.5em 0;

  @media ${device.tabletL} {
    margin: 0;
  }
`;

export const StyledHeading = styled(Heading)`
  font-size: 52px;
  line-height: 64px;

  @media ${device.tabletL} {
    font-size: 32px;
    line-height: 40px;
    text-align: left;
    margin: 0;
  }
`;

export const StyledText = styled(Paragraph)`
  @media ${device.tabletL} {
    font-size: 16px;
    line-height: 26px;
  }
`;

export const StyledAnchor = styled.a`
  @media ${device.tabletL} {
    margin-top: 12px;
    display: block;
    width: 100%;
    text-align: center;
  }
`;

export const StyledLink = styled(Link)`
  text-decoration: none;
  color: inherit;
  margin: 1em auto;
`;

export const StyledButton = styled(Button)`
  ${StyledParagraph} {
    font-size: 20px;
    line-height: 28px;
  }

  @media ${device.tabletL} {
    margin-top: 2em;
    width: 160px;
    padding: 10px 0;

    ${StyledParagraph} {
      font-size: 16px;
      line-height: 20px;
    }
  }
`;

export const PortfolioContainer = styled.div`
  width: 100%;
  max-width: 1080px;
  margin: 2em auto;

  @media ${device.tabletL} {
    width: 100%;
  }
`;

export const PortfolioImageContainer = styled.div`
  height: 160px;
  width: 100px;
  position: relative;
`;

export const PortfolioImage = styled.img`
  width: 60%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  @media ${device.tabletL} {
    width: 80%;
  }
`;
