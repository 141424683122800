import * as React from "react";

import HireTalentImage from "../../images/hire-talent.svg";
import TrophyIcon from "../../images/trophy-line.svg";
import RightHand from "../../images/thumb-up-line.svg";
import WorldIcon from "../../images/world-icon.svg";
import {
  HireTalentContainer,
  HireTalentImageContainer,
  PerkContainer,
  CardContainer,
  StyledCard,
  StyledCardContent,
  Subtitle,
  Description,
  Icon,
  StyledButton,
  MobileHeading,
  StyledAnchor,
  StyledLink,
} from "./HireTalent.styles";
import { Heading, Paragraph } from "../Typography/Typography";
import useMediaQuery from "../../hooks/useMediaQuery";
import { size } from "../../theme/sizes";

const HireTalent = () => {
  const isMobile = useMediaQuery(size.tabletL);

  return (
    <HireTalentContainer id="#hire-talent">
      {isMobile && (
        <MobileHeading weight="semiBold">
          We understand the pivotal role of tech talent in driving the success
          of your enterprise.
        </MobileHeading>
      )}
      <HireTalentImageContainer
        src={HireTalentImage}
        alt="hire-talent-you-need"
      />

      <PerkContainer>
        {!isMobile && (
          <Heading weight="semiBold">
            We understand the pivotal role of tech talent in driving the success
            of your enterprise.
          </Heading>
        )}
        <Paragraph>
          At alphathesis, we provide key talent and advisory services that
          elevate your team’s success.
        </Paragraph>
        <CardContainer>
          <StyledCard>
            <StyledCardContent>
              <Subtitle weight="semiBold">
                <Icon src={RightHand} alt="icon" />
                Hire Tech Talent
              </Subtitle>
              <Description>
                Outsource engineering tasks to save operating cost
              </Description>
            </StyledCardContent>
            <StyledLink
              to="/what-we-offer/hire-tech-talent"
            >
              <StyledButton label="Hire" weight="semiBold" />
            </StyledLink>
          </StyledCard>

          <StyledCard>
            <StyledCardContent>
              <Subtitle weight="semiBold">
                <Icon src={TrophyIcon} alt="icon" />
                Build an MVP
              </Subtitle>
              <Description>Design and build MVP affordably</Description>
            </StyledCardContent>
            <StyledLink
              to="/what-we-offer/building-mvp"
            >
              <StyledButton label="Build" weight="semiBold" />
            </StyledLink>
          </StyledCard>

          <StyledCard>
            <StyledCardContent>
              <Subtitle weight="semiBold">
                <Icon src={WorldIcon} alt="icon" />
                Advisory for Non-technical Founders
              </Subtitle>
              <Description>
                Navigate your startup journey with seasoned experts
              </Description>
            </StyledCardContent>
            <StyledLink to="/what-we-offer/fractional-cto">
              <StyledButton
                label="Get Advice"
                weight="semiBold"
              />
            </StyledLink>
          </StyledCard>
        </CardContainer>
      </PerkContainer>
    </HireTalentContainer>
  );
};

export default HireTalent;
