import * as React from "react";
import Slider from "react-slick";

import {
  MeetOurFounderContainer,
  MeetOurFounderContentContainer,
  Content,
  Container,
  StyledButton,
  StyledText,
  StyledHeading,
  StyledLink,
  PortfolioImageContainer,
  PortfolioContainer,
  PortfolioImage,
  FounderImage,
} from "./MeetOurFounder.styles";

import useMediaQuery from "../../hooks/useMediaQuery";
import { size } from "../../theme/sizes";
import { sliderCommonSettings } from "../../utils";

import MeetOurFounderImage from "../../images/meet-our-founder.png";
import WorkStride from "../../images/portfolio/image1.png";
import OracleSelectMinds from "../../images/portfolio/image6.png";
import EasternKentuckyUniversity from "../../images/portfolio/image9.png";
import CreditSuisse from "../../images/portfolio/image14.png";
import Antler from "../../images/portfolio/image15.png";
import NaiBAN from "../../images/portfolio/image23.png";
import LemonBrew from "../../images/portfolio/image26.png";
import Commonbond from "../../images/portfolio/image40.png";
import UniversityOfPennsylvania from "../../images/portfolio/image46.png";

const MeetOurFounder = () => {
  const isMobile = useMediaQuery(size.tabletL);

  const sliderConfig = {
    ...sliderCommonSettings,
    slidesToShow: isMobile ? 3 : 5,
  };

  const items: Array<{
    title: string;
    image: string;
  }> = [
    { title: "Eastern Kentucky University", image: EasternKentuckyUniversity },
    { title: "University of Pennsylvania", image: UniversityOfPennsylvania },
    { title: "Credit Suisse", image: CreditSuisse },
    { title: "Oracle Select Minds", image: OracleSelectMinds },
    { title: "Commonbond", image: Commonbond },
    { title: "NaiBAN", image: NaiBAN },
    { title: "WorkStride", image: WorkStride },
    { title: "LemonBrew", image: LemonBrew },
    { title: "Antler", image: Antler },
  ];

  return (
    <MeetOurFounderContainer id="#why-us">
      <MeetOurFounderContentContainer>
        {isMobile && <StyledHeading weight="semiBold">Why us?</StyledHeading>}
        <FounderImage src={MeetOurFounderImage} alt="meet-our-founder" />
        <Content>
          {!isMobile && (
            <StyledHeading weight="semiBold">Why us?</StyledHeading>
          )}
          <Container>
            <StyledText>
              We believe that building meaningful tech solutions is challenging.
              Finding the best talent for your team can be an expensive
              decision. At alphathesis, we connect you with highly effective
              tech talents at affordable price ranges.
            </StyledText>
            <StyledText>
              Our Founder Ashish is a software engineer with over a decade of
              experience architecting, building and delivering impactful tech
              solutions that are used by millions of users.
            </StyledText>
            <StyledText>
              His multi-faceted technical skills provide an edge in
              understanding your company’s challenges and talent needs.
            </StyledText>
          </Container>
        </Content>
      </MeetOurFounderContentContainer>
      <PortfolioContainer>
        <Slider {...sliderConfig} style={{ width: "100%", height:"150px", margin: "0" }}>
          {items.map((item, index) => (
            <PortfolioImageContainer key={index}>
              <PortfolioImage
                src={item.image}
                alt={item.title}
              />
            </PortfolioImageContainer>
          ))}
        </Slider>
      </PortfolioContainer>
      <StyledLink to="/about-us#our-story">
        <StyledButton label="Learn More" variant="outline" weight="semiBold" />
      </StyledLink>
    </MeetOurFounderContainer>
  );
};

export default MeetOurFounder;
